<template>
    <div>
        <div class="inventory-alerts">
            <styled-callout
                v-if="dealerWebsiteRdi"
                class="layout align-center mb-3">
                <div class="flex shrink mr-3">
                    <status-icon size="25" />
                </div>
                <div class="flex">
                    <h2>You Have Successfully Configured Website Inventory</h2>
                    <p>
                        You can continue to review the inventory here but it cannot
                        be changed during onboarding.  If you would like to make a
                        change visit the dealer dashboard.
                    </p>
                </div>
            </styled-callout>
            <v-alert
                type="error"
                class="mb-4"
                :value="error">
                {{ error }}
            </v-alert>
        </div>
        <div class="inventory-review mb-5">
            <div class="text-xs-center mb-4">
                <h2>Website Inventory</h2>
            </div>
            <website-inventory
                :website="dealerHostname" />
        </div>
        <template v-if="!dealerWebsiteRdi">
            <terms-confirmation
                :onboarding-flow="true"
                :loading="loadingRdiSetup"
                @approved="onApprove"
                @declined="onDecline" />
        </template>
        <div
            v-else
            class="layout align-center action-buttons">
            <back-button
                @click="$store.dispatch('goToPreviousStep')" />
            <styled-button
                class="ml-auto"
                @click="$store.dispatch('goToNextStep')">
                CONTINUE
            </styled-button>
        </div>
    </div>
</template>

<script>
import BackButton from '@/components/globals/BackButton';
import StatusIcon from '@/components/globals/StatusIcon';
import StyledCallout from '@/components/globals/StyledCallout';
import WebsiteInventory from '@/components/globals/WebsiteInventory';
import StyledButton from '@/components/globals/StyledButton';
import TermsConfirmation from '@/components/globals/TermsConfirmation';
import { WEBSITE_INVENTORY_API_SOURCE, WEBSITE_INVENTORY_SOURCE_TYPE } from '@/helpers/globals';
import { UPDATE_ONBOARDING_DATA } from '@/store/mutation-types';
import { mapState, mapGetters } from 'vuex';

export default {
    name: 'OnboardingWebsiteInventory',
    components: {
        BackButton,
        WebsiteInventory,
        StyledButton,
        StyledCallout,
        StatusIcon,
        TermsConfirmation
    },
    data() {
        return {
            error: null,
            loadingRdiSetup: false
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            onboardingData: (state) => state.onboarding.data
        }),
        ...mapGetters([
            'dealerHostname',
            'dealerRootDomain',
            'dealerWebsiteRdi'
        ]),
        createdRdi: {
            get() {
                return this.onboardingData.createdRdi || null;
            },
            set(id) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, { createdRdi: id });
            }
        },
    },
    methods: {
        async onApprove() {
            this.loadingRdiSetup = true;
            this.error = null;
            await this.createRdi();
            await this.processRdi();
            this.loadingRdiSetup = false;
            this.$store.dispatch('goToNextStep');
        },
        onDecline() {
            this.$store.dispatch('goToPreviousStep');
        },
        async createRdi() {
            try {
                const data = {
                    feed_provider_id: WEBSITE_INVENTORY_API_SOURCE,
                    rdi_type: WEBSITE_INVENTORY_SOURCE_TYPE,
                    id_string: this.dealerRootDomain,
                    process_inventory: true,
                    upload_exports: true,
                    notification_email: [],
                };
                const response = await this.$http.post(`dealers/${this.currentDealer.id}/remote_dealer_identifiers/create`, data);
                this.createdRdi = response.data.data.id;
            } catch(error) {
                // eslint-disable-next-line no-console
                console.error('Error creating new feed:', error);
                this.error = 'There was an error while creating new feed.  Please try again or contact support if the problem continues.';
            }
        },
        async processRdi() {
            try {
                const data = {
                    upload_exports: true,
                    notification_email: [],
                    upload_to_facebook: true,
                };
                await this.$http.post(`dealers/${this.currentDealer.id}/remote_dealer_identifiers/${this.createdRdi}/process-inventory`, data);
            } catch(error) {
                // eslint-disable-next-line no-console
                console.error('Error processing new feed:', error);
                this.error = 'There was an error trying to process the new feed.  Please try again or contact support if the problem continues.';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
button {
    &:active,
    &:focus {
        outline: none;
    }
}
.inventory-alerts {
    padding: 40px 40px 0;
}
.inventory-review, .action-buttons {
    padding: 40px;
}
</style>
